/* eslint-disable camelcase */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { OnboardingUser, Agreements, OnboardingResponse } from '@graphql/types/onboarding';
import { DocumentContext } from '@graphql/types/document';
import { ONBOARDING_QUERY } from '@graphql/queries/onboarding';
import { OperationResult, CombinedError } from '@urql/core';
import { useUrqlStore } from '@stores/useUrqlStore';

const storeSetup = () => {
  const user = ref<OnboardingUser | null >(null);
  const signedState = ref<string | null>(null);
  const mandateState = ref<string | null>(null);
  const agreements = ref<Agreements | null>(null);
  const documentContext = ref<DocumentContext[] | null>(null);
  const debitUrl = ref<string | null>(null);
  const error = ref<CombinedError | undefined>(undefined);
  const signing_id = ref('');
  const token = ref('');
  const step = ref(0);
  const loading = ref(true);
  const manualSign = ref(false);
  const mandateLoading = ref(false);
  const isWrongIban = ref(false);
  const correctIban = ref('');
  const isUploading = ref(false);
   
  const documents = computed(() => documentContext.value?.[0].documentOwners?.[0].items);

  const resetState = () => {
    user.value = null;
    signedState.value = null;
    mandateState.value = null;
    agreements.value = null;
    documentContext.value = null;
    debitUrl.value = null;
    signing_id.value = '';
    token.value = '';
    step.value = 0;
    error.value = undefined;
    loading.value = true;
    manualSign.value = false;
    mandateLoading.value = false;
    isUploading.value = false;
  };

  const store = (data: OnboardingResponse) => {
    user.value = data.onboarding.me;
    signedState.value = data.onboarding.status;
    debitUrl.value = data.onboarding.direct_debit_authorization_url;
    mandateState.value = data.onboarding.direct_debit_authorized_at;
    agreements.value = data.onboarding.agreements;
    documentContext .value= data.onboarding.documentContext;
  };

  const setLoading = (isLoading: boolean) => {
    loading.value = isLoading;
  };

  const setMandateLoading = (isLoading: boolean) => {
    mandateLoading.value = isLoading;
  };

  const setManualSign = (value: boolean) => {
    manualSign.value = value;
  }

  const storeTokens = (signingId: string, tokenString: string) => {
    signing_id.value = signingId;
    token.value = tokenString;
  };

  const goToNextStep = () => {
    if (debitUrl.value !== null) {
      step.value = 2;
    } else {
      step.value = 3;
    }
  };

  const directDebitNextStep = () => {
    step.value = 3;
  };

  const directDebitFailed = (iban: string) => {
    isWrongIban.value = true;
    step.value = 2;
    correctIban.value = iban;
  };

  const goToCorrectStepFromWelcome = (isInitial = false) => {
    const documentsArray = documentContext.value?.[0].documentOwners?.[0].items;
    const amountOfDocumentsToSign = documentContext.value?.[0].documentOwners?.[0].items.length ?? [];

    if (isInitial) {
      step.value = 0;
    } else if (signedState.value !== 'SIGNED' || documentsArray?.length !== amountOfDocumentsToSign) {
      step.value = 1;
    } else if (debitUrl.value !== null && mandateState.value === null) {
      step.value = 2;
    } else {
      step.value = 3;
    }
  };

  const setError = (combinedError: CombinedError | undefined) => {
    error.value = combinedError;
  };

  const setDownloadUrlOnTemplate = (documentIndex: number, link: string) => {
    if (documentContext.value?.[0].documentOwners?.[0]) {
      documentContext.value[0].documentOwners[0].items[documentIndex].template.absolute_url = link;
    }
  };

  const setIsUploading = (value: boolean) => {
    isUploading.value = value;
  };

  const getUser = async (signing_id: string, token: string): Promise<OnboardingResponse> => {
    loading.value = true;
    return new Promise(async resolve => {
      const { gqlClient } = toRefs(useUrqlStore());

      await gqlClient.value
        .query<OnboardingResponse>(ONBOARDING_QUERY, { signing_id, token })
        .toPromise()
        .then((result: OperationResult<OnboardingResponse>) => {
          if (result.data?.onboarding) {
            store(result.data);
            storeTokens(signing_id, token);
            goToCorrectStepFromWelcome();
            resolve(result.data);
          } else {
            showError({ message: 'Link ongeldig', statusCode: 404 });
          }
        })
        .catch((error: CombinedError) => {
          setError(error);
          showError({ message: 'Link ongeldig', statusCode: 404 });
        });
        loading.value = false;
    });
  };



  return {
    // State
    user,
    signedState,
    mandateState,
    agreements,
    documentContext,
    debitUrl,
    error,
    signing_id,
    token,
    step,
    loading,
    manualSign,
    mandateLoading,
    isWrongIban,
    correctIban,
    isUploading,

    // Getters
    documents,

    // Actions
    resetState,
    store,
    setLoading,
    setMandateLoading,
    setManualSign,
    goToNextStep,
    directDebitNextStep,
    directDebitFailed,
    goToCorrectStepFromWelcome,
    setError,
    setDownloadUrlOnTemplate,
    setIsUploading,
    getUser,
  };
};

export const useSignStore = defineStore('useSignStore', storeSetup, {
  persistedState: {
   persist: false,
  },
  share: {
    initialize: false,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSignStore, import.meta.hot));
}
